import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import Spinner from "../Spinner";

const PaymentStatus = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("orderId");
  const domainname = window.location.hostname;

  const userAddress = JSON.parse(localStorage.getItem("address"));
  const userProductData = JSON.parse(localStorage.getItem("slectedData"));

  const createOrder = async (transitionId, amount) => {
    try {
      const orderData = {
        orderId,
        products: userProductData,
        address: userAddress,
        transitionId,
        amount,
        domainname,
      };

      const res = await axios.post(
        `${process.env.REACT_APP_PHONEPE_NODE_URL}/api/order`,
        orderData
      );

      if (res.status === 201 || res.status === 200) {
        navigate(`/thankyou?txnId=${orderId}`);
      } else {
        navigate("/cart");
      }
    } catch (error) {
      console.error("Order creation failed:", error);
      navigate("/cart");
    }
  };

  useEffect(() => {
    if (process.env.REACT_APP_PAYMENT_TYPE === "phonepe") {
      if (orderId) {
        axios
          .get(
            `${process.env.REACT_APP_PHONEPE_NODE_URL}/api/phonepay/sdk/order-status/${orderId}`
          )
          .then((response) => {
            if (response?.data?.state === "COMPLETED") {
              const transitionId =
                response?.data?.paymentDetails?.length > 0
                  ? response?.data?.paymentDetails[0].transactionId
                  : null;
              createOrder(transitionId, response?.data?.amount || 0);
            } else {
              navigate("/cart");
            }
          })
          .catch(() => {
            navigate("/cart");
          });
      }
    } else if (process.env.REACT_APP_PAYMENT_TYPE === "indianpay") {
      if (orderId) {
        axios
          .get(
            `${process.env.REACT_APP_PHONEPE_NODE_URL}/api/pay/order-status/${orderId}`
          )
          .then((response) => {
            if (response?.data?.status === "success") {
              const transitionId = response?.data?.transactionid
                ? response?.data?.transactionid
                : null;
              createOrder(transitionId, response?.data?.amount || 0);
            } else {
              navigate("/cart");
            }
          })
          .catch(() => {
            navigate("/cart");
          });
      }
    } else if (process.env.REACT_APP_PAYMENT_TYPE === "cashfree") {
      if (orderId) {
        axios
          .post(
            `${process.env.REACT_APP_PHONEPE_NODE_URL}/api/cashfree/verify-payment`,
            {
              orderId,
              appId: process.env.REACT_APP_CASHFREE_APP_ID,
              secretKey: process.env.REACT_APP_CASHFREE_SECRET_KEY,
            }
          )
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              navigate(`/thankyou?txnId=${orderId}`);
            } else {
              navigate("/cart");
            }
          })
          .catch(() => {
            navigate("/cart");
          });
      } else {
        console.log("");
      }
    }
  }, [orderId, navigate]);

  return <Spinner />;
};

export default PaymentStatus;
