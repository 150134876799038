import axios from "axios";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { ReactComponent as ShipImage } from "../../assets/ship.svg";
import Spinner from "../Spinner";

const NewOrderTracking = ({ isAfterPayment = false }) => {
  const [error, setError] = useState(false);
  const [value, setValue] = useState("");
  const [isTrack, setIsTrack] = useState(false);
  const [orderData, setOrderData] = useState(null);
  const [logistics, setLogistics] = useState(null);
  const [loading, setLoading] = useState(false);

  const API_URL = process.env.REACT_APP_PHONEPE_NODE_URL;

  // Update order logistics
  const updateOrderLogistics = async (orderId, logisticsObj) => {
    setLoading(true);
    try {
      const response = await axios.put(`${API_URL}/api/order/${orderId}`, {
        orderLogistics: logisticsObj,
      });

      if (response?.data?.orderLogistics) {
        setOrderData((prev) => ({
          ...prev,
          orderLogistics: response.data.orderLogistics,
        }));
        setLogistics(response?.data?.orderLogistics);
      }
    } catch (err) {
      console.error("Error updating order logistics:", err.response || err);
    } finally {
      setLoading(false);
    }
  };

  // Fetch nearest logistics cities
  const fetchNearestCities = async () => {
    if (!orderData?.orderId) return null;
    setLoading(true);
    try {
      const response = await axios.post(`${API_URL}/api/order/state`, {
        orderId: orderData.orderId,
        state: orderData.address?.state,
        city: orderData.address?.city,
        storeAddress: "Surat",
      });

      if (response.data) {
        return {
          orderLogisticsCity: response.data.orderLogisticsCity || [],
          orderLogisticsCenter: response.data.orderLogisticsCenter || null,
        };
      }
    } catch (err) {
      console.error("Error fetching nearest cities:", err);
    } finally {
      setLoading(false); // Stop loading
    }

    return null;
  };

  // Fetch order details
  const fetchOrder = async () => {
    if (!value.trim()) {
      setError("Please enter a valid Order ID.");
      return;
    }
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/api/order/${value}`);
      if (response.data) {
        setOrderData(response.data);
        setIsTrack(true);
        setError(false);
      }
    } catch (err) {
      setError("Order not found.");
    } finally {
      setLoading(false);
    }
  };

  const [logisticsUpdated, setLogisticsUpdated] = useState(false);

  useEffect(() => {
    // if (
    //   !orderData?.orderId ||
    //   (orderData?.orderLogistics &&
    //     Object.keys(orderData?.orderLogistics).length > 0)
    // ) {
    //   return;
    // }
    //

    if (
      !orderData?.orderId ||
      (orderData?.orderLogistics &&
        orderData?.orderLogistics?.orderLogisticsCenter !== null)
    ) {
      return;
    }

    const generateLogisticsData = async () => {
      const orderCreatedAt = new Date(orderData?.createdAt);
      const nextDay10AM = new Date(orderCreatedAt);
      nextDay10AM.setDate(nextDay10AM.getDate() + 1);
      nextDay10AM.setHours(10, 0, 0, 0);

      const randomOffsetShip =
        Math.floor(Math.random() * (60 - 30 + 1) + 30) * 60 * 1000;
      const orderShipDate = new Date(nextDay10AM.getTime() + randomOffsetShip);

      const nearestCities = await fetchNearestCities();
      if (!nearestCities) return;

      const orderLogisticsCity = nearestCities.orderLogisticsCity
        .slice(0, 2)
        .map((cityObj, index) => {
          const cityDate = new Date(orderCreatedAt);
          cityDate.setDate(cityDate.getDate() + 4 + index);
          cityDate.setHours(9, 0, 0, 0);
          return { date: cityDate.toISOString(), city: cityObj.city };
        });

      const getRandomTime = (date) => {
        const hours = Math.floor(Math.random() * (11 - 9 + 1)) + 9; // Random hour between 9 and 11
        const minutes = Math.floor(Math.random() * 60); // Random minutes
        const seconds = Math.floor(Math.random() * 60); // Random seconds

        const randomDate = new Date(date);
        randomDate.setHours(hours, minutes, seconds, 0); // Set random time

        return randomDate;
      };

      // Function for random time between 6 PM - 7 PM
      const getRandomTimeBetween6PMto7PM = (date) => {
        const randomDate = new Date(date);
        const hours = Math.floor(Math.random() * (19 - 18 + 1)) + 18;
        const minutes = Math.floor(Math.random() * 60);
        const seconds = Math.floor(Math.random() * 60);

        randomDate.setHours(hours, minutes, seconds, 0);
        return randomDate;
      };

      const orderLogisticsCenter = nearestCities.orderLogisticsCenter
        ? {
            date: getRandomTimeBetween6PMto7PM(
              new Date(orderCreatedAt.setDate(orderCreatedAt.getDate() + 1))
            ),
            city: nearestCities.orderLogisticsCenter.city,
          }
        : null;

      // Set "Out For Delivery" date (5 days after order creation)
      const outForDeliveryDate = new Date(orderCreatedAt);
      outForDeliveryDate.setDate(orderCreatedAt.getDate() + 5);
      outForDeliveryDate.setHours(9, 0, 0, 0);

      // Set "Delivery" date (5 days after order creation)
      const deliveryDate = new Date(orderCreatedAt);
      deliveryDate.setDate(deliveryDate.getDate() + 6); // Add 6 days
      deliveryDate.setHours(9, 0, 0, 0); // Set time to 9:00 AM

      const logisticsObj = {
        orderConfirmed: orderData?.createdAt,
        orderShipDate,
        orderLogisticsCenter,
        orderLogisticsCity: orderLogisticsCity.map((cityData) => ({
          ...cityData,
          date: getRandomTime(cityData.date),
        })),
        outOfDeliveryDate: outForDeliveryDate,
        deliveryStatus: { date: deliveryDate, status: "pending" },
      };

      await updateOrderLogistics(orderData.orderId, logisticsObj);
      setLogistics(logisticsObj);
      setLogisticsUpdated(true);
    };

    generateLogisticsData();
  }, [orderData, logisticsUpdated]);

  const getStepProgress = () => {
    if (!orderData) return [];

    const logisticsData = orderData?.orderLogistics;
    const now = new Date();

    const parseDate = (dateString) => {
      const date = new Date(dateString);
      return isNaN(date) ? null : date;
    };

    const orderConfirmedDate = parseDate(logisticsData?.orderConfirmed);
    const orderShipDate = parseDate(logisticsData?.orderShipDate);
    const logisticsCenterDate = parseDate(
      logisticsData?.orderLogisticsCenter?.date
    );
    const outForDeliveryDate = parseDate(logisticsData?.outOfDeliveryDate);
    const deliveryCompletedDate = parseDate(
      logisticsData?.deliveryStatus?.date
    );

    console.log(outForDeliveryDate, "outForDeliveryDate");

    const isCompleted = logisticsData?.deliveryStatus?.status === "completed";

    const hasReachedTime = (date) => date && now >= date;
    const generateRandomPin = () => Math.floor(100000 + Math.random() * 900000);

    const steps = [
      {
        label: "Order Confirmed",
        date: orderConfirmedDate
          ? moment(orderConfirmedDate).format("MMM DD, YYYY, h:mm A")
          : "Upcoming",
        completed: hasReachedTime(orderConfirmedDate),
      },
      {
        label: "Shipped",
        date: hasReachedTime(orderShipDate)
          ? moment(orderShipDate).format("MMM DD, YYYY, h:mm A")
          : "Upcoming",
        completed: hasReachedTime(orderShipDate),
      },
      {
        label: hasReachedTime(logisticsCenterDate) ? (
          <>
            Courier Logistics Center
            <br />
            {logisticsData?.orderLogisticsCenter?.city}
          </>
        ) : (
          "Upcoming"
        ),
        className: hasReachedTime(logisticsCenterDate) ? "step-line" : "",
        date: hasReachedTime(logisticsCenterDate)
          ? moment(logisticsCenterDate).format("MMM DD, YYYY, h:mm A")
          : "Upcoming",
        completed: hasReachedTime(logisticsCenterDate),
      },
      ...(logisticsData?.orderLogisticsCity || []).map((cityObj) => {
        const cityDate = parseDate(cityObj.date);
        return {
          label: hasReachedTime(cityDate) ? cityObj.city : "Upcoming",
          className: "small-bullet",
          date: hasReachedTime(cityDate)
            ? moment(cityDate).format("MMM DD, YYYY, h:mm A")
            : "Upcoming",
          completed: hasReachedTime(cityDate),
        };
      }),
      {
        label: hasReachedTime(outForDeliveryDate)
          ? `Out For Delivery${
              now.toDateString() === outForDeliveryDate.toDateString()
                ? " Today - Your Logistic PIN:\n" + generateRandomPin()
                : ""
            }`
          : "Upcoming",
        className:
          hasReachedTime(logisticsCenterDate) && now === outForDeliveryDate
            ? "step-line"
            : "",
        date: hasReachedTime(outForDeliveryDate)
          ? moment(outForDeliveryDate).format("MMM DD, YYYY, h:mm A")
          : "Upcoming",
        completed: hasReachedTime(outForDeliveryDate),
      },
      {
        label: "Delivered",
        date: isCompleted
          ? moment(deliveryCompletedDate).format("MMM DD, YYYY, h:mm A")
          : "Upcoming",
        completed: isCompleted,
      },
    ];
    console.log(hasReachedTime(logisticsCenterDate), "------->");

    return steps;
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <Container>
          <Row>
            <Col>
              {!isTrack && (
                <div className="text-center mt-5">
                  <h3
                    style={{
                      fontSize: "18px",
                      fontWeight: 700,
                      color: "#727272",
                      textAlign: "center",
                    }}
                    className="mt-4"
                  >
                    Track Your Order
                  </h3>
                  <div className="Footer__Content Rte">
                    Enter your order id and track your order here.
                  </div>
                  <Form
                    method="post"
                    action="#"
                    acceptCharset="UTF-8"
                    className="my-3"
                  >
                    <Form.Control
                      size="lg"
                      className="Utr-number"
                      type={isAfterPayment ? "number" : "text"}
                      onChange={(e) => setValue(e.target.value)}
                      placeholder={"Order Id"}
                    />
                    {error && (
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: 500,
                          color: "#FF0000",
                          textAlign: "center",
                          marginTop: "5px",
                        }}
                      >
                        {error}
                      </div>
                    )}
                    <div className="d-flex justify-content-center">
                      <Button
                        type="button"
                        onClick={fetchOrder}
                        variant="dark"
                        className="mt-3"
                        style={{
                          background: "var(--them-color)",
                          borderColor: "var(--them-color)",
                        }}
                      >
                        Track
                      </Button>
                    </div>
                  </Form>
                </div>
              )}
              {isTrack && (
                <div className="mx-3">
                  <h4>Order ID: {orderData?.orderId}</h4>
                  <p>
                    <span className="fw-bold">Order date:</span>{" "}
                    {moment(orderData.createdAt).format("MMM DD, YYYY")}
                  </p>
                  <p>
                    <span>
                      <ShipImage />
                    </span>
                    &nbsp; Estimated delivery:{" "}
                    {moment(orderData.createdAt)
                      .add(7, "days")
                      .format("MMM DD, YYYY")}
                  </p>
                </div>
              )}
              {isTrack && (
                <div className="order-tracking">
                  <ul className="tracking-list">
                    {getStepProgress().map((step, index) => (
                      <li
                        key={index}
                        className={`tracking-item  ${step.className} ${
                          step.completed ? "completed" : ""
                        }`}
                      >
                        {index !== getStepProgress().length - 1 && (
                          <div
                            className={`tracking-line ${step.className} ${
                              step.completed ? "green-line" : "gray-line"
                            }`}
                          ></div>
                        )}
                        <div
                          className={`circle ${
                            step.completed
                              ? "completed-circle"
                              : "pending-circle"
                          }`}
                        ></div>
                        <div className="tracking-content">
                          <span className="status">{step.label}</span>
                          <span className="date">{step.date}</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default NewOrderTracking;
