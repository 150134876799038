import React, { useEffect } from "react";

const Spinner = () => {
  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
      .spinner-container {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100vh;
          height: calc(100vh - 381px);
      }

      .loader--roller {
          display: inline-block;
          position: relative;
          height: 64px;
          width: 64px;
      }

      .loader--roller div {
          animation: loader--roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          transform-origin: 32px 32px;
      }

      .loader--roller div:after {
          display: block;
          position: absolute;
          border-radius: 50%;
          content: " ";
          margin: -3px 0 0 -3px;
          height: 6px;
          width: 6px;
          background: ${process.env.REACT_APP_NAV_THEME_COLOR};
      }

      .loader--roller div:nth-child(1) { animation-delay: -0.036s; }
      .loader--roller div:nth-child(1):after { top: 50px; left: 50px; }

      .loader--roller div:nth-child(2) { animation-delay: -0.072s; }
      .loader--roller div:nth-child(2):after { top: 54px; left: 45px; }

      .loader--roller div:nth-child(3) { animation-delay: -0.108s; }
      .loader--roller div:nth-child(3):after { top: 57px; left: 39px; }

      .loader--roller div:nth-child(4) { animation-delay: -0.144s; }
      .loader--roller div:nth-child(4):after { top: 58px; left: 32px; }

      .loader--roller div:nth-child(5) { animation-delay: -0.18s; }
      .loader--roller div:nth-child(5):after { top: 57px; left: 25px; }

      .loader--roller div:nth-child(6) { animation-delay: -0.216s; }
      .loader--roller div:nth-child(6):after { top: 54px; left: 19px; }

      .loader--roller div:nth-child(7) { animation-delay: -0.252s; }
      .loader--roller div:nth-child(7):after { top: 50px; left: 14px; }

      .loader--roller div:nth-child(8) { animation-delay: -0.288s; }
      .loader--roller div:nth-child(8):after { top: 45px; left: 10px; }

      @keyframes loader--roller {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
      }
    `;

    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return (
    <div className="spinner-container">
      <div className="loader--roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Spinner;
