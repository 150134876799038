import { SHA256 } from "crypto-js";
import moment from "moment/moment";
import { useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

const ThankYou = () => {
  const navigate = useNavigate();

  const { handleSetCartProducts } = useAuth();

  const location = useLocation();
  const userData = JSON.parse(localStorage.getItem("address"));
  const queryParams = new URLSearchParams(location.search);
  const txnId = queryParams.get("txnId");

  useEffect(() => {
    handleSetCartProducts([]);
  }, []);

  const encodeData = (data) => {
    return SHA256(data?.toString()?.trim().toLowerCase());
  };

  const totalAmount = localStorage.getItem("totalPrice");

  // setTimeout(() => {
  //   localStorage.clear();
  // }, 3 * 60 * 1000);

  const hasExecutedRef = useRef(false); // Ref to track if script has already run

  const address = `${encodeData(userData?.address1 || "")}, ${encodeData(
    userData?.address2 || ""
  )}`;

  // useEffect(() => {
  //   // Only run if txnId exists and the script has not been executed before
  //   if (txnId && !hasExecutedRef.current) {
  //     hasExecutedRef.current = true; // Mark that the script has run

  //     // Google Ads conversion tracking script
  //     if (process.env.REACT_APP_AW && txnId) {
  //       const googleAdsScript = document.createElement("script");
  //       googleAdsScript.innerHTML = `
  //         gtag('event', 'conversion', {
  //           'send_to': '${process.env.REACT_APP_AW}/${
  //         process.env.REACT_APP_PURCHASETAGGOOGLE
  //       }',
  //           'value': ${totalAmount},
  //           'currency': 'INR',
  //           'transaction_id': '${txnId}',
  //           'user_data': {
  //             'phone_number': '${encodeData(userData?.mobile)}',
  //             'first_name': '${encodeData(userData?.fullname)}',
  //             'last_name': '${encodeData(userData?.fullname)}',
  //             'home_address': {
  //               'street': '${address}',
  //               'city': '${encodeData(userData?.city)}',
  //               'region': '${encodeData(userData?.state)}',
  //               'postal_code': '${encodeData(userData?.pincode)}',
  //               'country': 'IN'
  //             }
  //           }
  //         });
  //       `;
  //       document.head.appendChild(googleAdsScript);
  //     }
  //   }
  // }, [txnId]);

  const currentDate = moment().format("DD MMM YYYY");
  const expectedDeliveryDate = moment().add(7, "days").format("DD MMM YYYY");

  return (
    <div>
      {process.env.REACT_APP_FBPIXEL && txnId !== null && (
        <Helmet>
          <script>
            {`
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '${process.env.REACT_APP_FBPIXEL}');
fbq('track', 'Purchase', {
    value: '${totalAmount}',
    currency: 'INR',
    contents: [
        {
            id: '${txnId}',
        }
    ],
    content_type: 'product'
});
fbq('track', 'PageView');
`}
          </script>
        </Helmet>
      )}

      {process.env.REACT_APP_AW && (
        <Helmet>
          <script>
            {`
            gtag('event', 'conversion', {
      'send_to': '${process.env.REACT_APP_AW}/${
              process.env.REACT_APP_PURCHASETAGGOOGLE || ""
            }',
      'value': ${totalAmount},
      'currency': 'INR',
      'transaction_id': '${txnId}',
       'user_data':{
       'phone_number':  '${encodeData(userData?.mobile)}',
           'first_name': '${encodeData(userData?.fullname)}',
          'last_name':'${encodeData(userData?.fullname)}',
          'home_address': {
          'street': '${address}',
          'city':'${encodeData(userData?.city)}',
          'region': '${encodeData(userData?.state)}',
          'postal_code': '${encodeData(userData?.pincode)}',
          'country': 'IN'
       }
          }
  });
          `}
          </script>
        </Helmet>
      )}
      <div>
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ backgroundColor: "#F5F5F5", padding: "12px" }}
        >
          <h5 style={{ color: process.env.REACT_APP_THEAM_COLOR }}>
            Order Summury
          </h5>
          <h4 style={{ color: process.env.REACT_APP_THEAM_COLOR }}>
            ₹ {totalAmount}
          </h4>
        </div>

        <div className="d-flex p-3 gap-4  mx-4 mt-3 justify-content-center">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="46"
              height="46"
              viewBox="0 0 46 46"
              fill="none"
            >
              <path
                d="M23 0C10.3095 0 0 10.3095 0 23C0 35.6905 10.3095 46 23 46C35.6905 46 46 35.6905 46 23C46 10.3095 35.6905 0 23 0ZM23 2C34.6096 2 44 11.3905 44 23C44 34.6096 34.6096 44 23 44C11.3905 44 2 34.6096 2 23C2 11.3905 11.3905 2 23 2ZM32.9883 12.9883C32.8258 12.9915 32.6665 13.0343 32.5243 13.1129C32.382 13.1915 32.2611 13.3036 32.1719 13.4395L21.9707 28.4766L14.6797 21.711C14.5839 21.6188 14.4707 21.5467 14.3468 21.4987C14.2228 21.4508 14.0905 21.4281 13.9576 21.4319C13.8248 21.4357 13.694 21.466 13.573 21.5209C13.4519 21.5758 13.3431 21.6543 13.2527 21.7518C13.1624 21.8493 13.0924 21.9638 13.0468 22.0887C13.0012 22.2135 12.981 22.3462 12.9873 22.479C12.9936 22.6117 13.0263 22.7419 13.0834 22.8619C13.1406 22.9819 13.2212 23.0892 13.3203 23.1778L22.3164 31.5254L33.8281 14.5606C33.9337 14.4093 33.9954 14.2317 34.0064 14.0475C34.0173 13.8633 33.9771 13.6797 33.8902 13.517C33.8032 13.3543 33.673 13.2187 33.5138 13.1254C33.3546 13.0321 33.1728 12.9847 32.9883 12.9883Z"
                fill="#40C057"
              />
            </svg>
          </div>
          <div>
            <p className="m-0"> Order Confirmation</p>
            <p className="m-0">
              <span className="fw-bold">Thank You</span>  
            </p>
          </div>
        </div>

        <div className="d-flex justify-content-center gap-4 border rounded mx-4 p-3 mt-4 align-items-center">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
            >
              <path
                d="M12.5 1C6.15477 1 1 6.15477 1 12.5C1 18.8452 6.15477 24 12.5 24C18.8452 24 24 18.8452 24 12.5C24 6.15477 18.8452 1 12.5 1ZM12.5 2C18.3048 2 23 6.69524 23 12.5C23 18.3048 18.3048 23 12.5 23C6.69524 23 2 18.3048 2 12.5C2 6.69524 6.69524 2 12.5 2ZM17.4942 7.49415C17.4129 7.49575 17.3333 7.51713 17.2621 7.55643C17.191 7.59574 17.1305 7.65179 17.0859 7.71973L11.9854 15.2383L8.33985 11.8555C8.29196 11.8094 8.23537 11.7733 8.17338 11.7494C8.11139 11.7254 8.04525 11.7141 7.97882 11.716C7.91239 11.7179 7.84701 11.733 7.78649 11.7604C7.72597 11.7879 7.67154 11.8272 7.62636 11.8759C7.58119 11.9246 7.54619 11.9819 7.5234 12.0443C7.50061 12.1068 7.49049 12.1731 7.49364 12.2395C7.49679 12.3059 7.51313 12.371 7.54172 12.4309C7.57032 12.4909 7.61058 12.5446 7.66016 12.5889L12.1582 16.7627L17.9141 8.28028C17.9669 8.20463 17.9977 8.11585 18.0032 8.02376C18.0087 7.93167 17.9885 7.83987 17.9451 7.7585C17.9016 7.67713 17.8365 7.60937 17.7569 7.56271C17.6773 7.51606 17.5864 7.49233 17.4942 7.49415Z"
                fill="black"
              />
            </svg>
          </div>
          <div>
            <h4 className="fw-bold m-0">Confirmed</h4>
            <p className="m-0">{currentDate}</p>
            <p className="m-0">We've received your order.</p>
            <p className="m-0">Expected delivery date {expectedDeliveryDate}</p>
          </div>
        </div>

        <div className="p-3 border rounded mx-4 mt-4 ">
          <h3 className="fw-bold text-center">Order Details</h3>
                  <div className="mt-4">
            <h5 className="m-0 fw-medium">Your Order Id</h5>
            <p className="m-0"> {txnId}</p>
          </div>
          <div className="mt-4">
            <h5 className="m-0 fw-medium">Contact information</h5>
            <p className="m-0"> +91 {userData?.mobile}</p>
          </div>
          <div className="mt-3">
            <h5 className="m-0 fw-medium">Payment method</h5>
            <p className="m-0">Online</p>
          </div>
          <div>
            <h5 className="m-0 fw-medium mt-3">Shipping Address</h5>
            <p className="m-0">
              {userData?.address1}
              {userData?.address2}
            </p>
          </div>
        </div>

        <div className="my-4">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4514.991084153124!2d72.81675407583587!3d18.96954405530466!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7ce6c2e076891%3A0x54994016529c9c1d!2sMumbai%20Central%20Railway%20Station%20Building%2C%20Mumbai%20Central%2C%20Mumbai%2C%20Maharashtra%20400008!5e1!3m2!1sen!2sin!4v1742297114909!5m2!1sen!2sin"
            width="100%"
            height="250"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      <Button
        variant="dark"
        className="btn my-5 primary d-flex m-auto justify-content-center align-items-center ripple animated"
        style={{
          padding: "10px 20px",
          background: "var(--them-color)",
          borderColor: "var(--them-color)",
        }}
        onClick={(e) => {
          e?.target?.classList?.add("bounceIn");
          navigate(`/`);
          setTimeout(() => {
            if (e?.target?.classList?.contains("bounceIn"))
              e?.target?.classList?.remove("bounceIn");
          }, 1000);
        }}
      >
        CONTINUE SHOPPING
      </Button>
    </div>
  );
};
export default ThankYou;
