import React from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";

const TermsAndConditions = () => {
  return (
    <Container className="container mx-auto px-4 py-8">
      <div>
        <h1 className="text-2xl font-bold mb-4">Terms & Conditions</h1>

        <p className="mb-4">
          This document is an electronic record in terms of the Information
          Technology Act, 2000 and rules thereunder as applicable and the
          amended provisions pertaining to electronic records in various
          statutes as amended by the Information Technology Act, 2000. This
          electronic record is generated by a computer system and does not
          require any physical or digital signatures.
        </p>

        <p className="mb-4">
          This document is published in accordance with the provisions of Rule 3
          (1) of the Information Technology (Intermediaries guidelines) Rules,
          2011, which require publishing the rules and regulations, privacy
          policy, and Terms of Use for access or usage of domain name{" "}
          <strong>{window.location.hostname}</strong> ("Website"), including the
          related mobile site and mobile application (hereinafter referred to as
          "Platform").
        </p>

        <p className="mb-4">
          The Platform is owned by {window.location.hostname}, managed by{" "}
          <strong>{process.env.REACT_APP_FAM}</strong>, with its address at{" "}
          <strong>{process.env.REACT_APP_ADDRESS}</strong> (hereinafter referred
          to as "Platform Owner", "we", "us", "our"). Your use of the Platform
          and services and tools are governed by the following terms and
          conditions ("Terms of Use") as applicable to the Platform, including
          the applicable policies which are incorporated herein by way of
          reference. By mere use of the Platform, You shall be contracting with
          the Platform Owner, and these terms and conditions, including the
          policies, constitute Your binding obligations with the Platform Owner.
        </p>
 <p className="mb-4">
         
              Governing laws 
      All the laws are applicable as
      per Indian laws governed by the  state of india,
      applicable to state to Gujarat and the  same will be
      applicable for any case involving our website.
        </p>
        {/* Other sections remain unchanged */}
      </div>

      <div className="text-center mt-6">
        <h3
          style={{
            fontSize: "18px",
            fontWeight: 700,
            color: "#727272",
            textAlign: "center",
          }}
        >
          Get Our Newsletter
        </h3>
        <div className="Footer__Content Rte">
          Subscribe to receive updates, access to exclusive deals, and more.
        </div>
        <Form
          method="post"
          action="/contact#footer-newsletter"
          id="footer-newsletter"
          acceptCharset="UTF-8"
          className="my-3"
        >
          <input type="hidden" name="form_type" value="customer" />
          <input type="hidden" name="utf8" value="✓" />
          <input type="hidden" name="contact[tags]" value="newsletter" />
          <Form.Control
            size="lg"
            type="email"
            placeholder="Enter email"
            className="mb-3"
          />
          <Button
            type="submit"
            variant="dark"
            className="mt-3"
            style={{
              background: "var(--them-color)",
              borderColor: "var(--them-color)",
            }}
          >
            Subscribe
          </Button>
        </Form>
      </div>
    </Container>
  );
};

export default TermsAndConditions;
