import React, { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./sidebar.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ReactComponent as UserIcon } from "../../../../assets/userIcon.svg";
import { Accordion } from "react-bootstrap";

const SidebarMenu = ({ show, handleClose }) => {
  const [categoryArray, setCategoryArray] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/category/get`)
      .then(function (response) {
        // handle success
        if (response.data.data.length > 0 && response.data.statusCode === 1) {
          setCategoryArray(response.data.data);
        }
      })
      .catch(function (error) {
        // handle error
        console.log("---- error", error);
        setCategoryArray([]);
      });
  }, []);

  return (
    <>
      <div className="category_main">
        <Offcanvas show={show} onHide={handleClose} className="w-75">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title className="fw-bold">Category</Offcanvas.Title>
          </Offcanvas.Header>
          <div className="border"></div>
          <Offcanvas.Body>
            <div className="d-flex flex-column justify-content-between h-100">
              <div>
                {categoryArray?.length > 0 &&
                  categoryArray?.map((item, index) => {
                    return (
                      <>
                        <div
                          key={index}
                          onClick={() => {
                            navigate(`/category/${item._id}`);
                            handleClose();
                          }}
                          className="category_title my-2 py-3"
                        >
                          {item?.name}
                        </div>
                      </>
                    );
                  })}
              </div>

              <div className="">
                <hr />
                <Accordion className="p-0 mt-3">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header
                      className="contact-us-btn"
                      onClick={() => {
                        navigate("/contact-us");
                        handleClose();
                      }}
                    >
                      <span>Contact Us</span>
                    </Accordion.Header>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Our Policy</Accordion.Header>
                    <Accordion.Body className="text-center">
                      <div
                        style={{ color: "rgb(40, 44, 63)", fontSize: "13px" }}
                        className="my-3"
                        onClick={() => {
                          navigate("/refund-policy");
                          handleClose();
                        }}
                      >
                        Refund Policy
                      </div>
                      <div
                        style={{ color: "rgb(40, 44, 63)", fontSize: "13px" }}
                        className="my-3"
                        onClick={() => {
                          navigate("/privacypolicy");
                          handleClose();
                        }}
                      >
                        Privacy policy
                      </div>
                      <div
                        style={{ color: "rgb(40, 44, 63)", fontSize: "13px" }}
                        className="my-3"
                        onClick={() => {
                          navigate("/shippingpolicy");
                          handleClose();
                        }}
                      >
                        Shipping policy
                      </div>
                      <div
                        style={{ color: "rgb(40, 44, 63)", fontSize: "13px" }}
                        className="my-3"
                        onClick={() => {
                          navigate("/termsofservice");
                          handleClose();
                        }}
                      >
                        Terms of service
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>QUICK LINKS</Accordion.Header>
                    <Accordion.Body className="text-center">
                      <div
                        style={{ color: "rgb(40, 44, 63)", fontSize: "13px" }}
                        className="my-3"
                        onClick={() => {
                          navigate("/about-us");
                          handleClose();
                        }}
                      >
                        About Us
                      </div>
                      <div
                        style={{ color: "rgb(40, 44, 63)", fontSize: "13px" }}
                        className="my-3"
                        onClick={() => {
                          navigate("/order-tracking");
                          handleClose();
                        }}
                      >
                        Track Order
                      </div>
                      <div
                        style={{ color: "rgb(40, 44, 63)", fontSize: "13px" }}
                        className="my-3"
                        onClick={() => {
                          navigate("/faqs");
                          handleClose();
                        }}
                      >
                        FAQ
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <hr />
                <div className="text-center">
                  <UserIcon />
                  login
                </div>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};
export default SidebarMenu;
